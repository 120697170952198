.fluidMedia {
  position: relative;
  padding-bottom: 174.5%;
  height: 0;
  overflow: hidden;
}

.fluidMedia iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: black;
  box-sizing: border-box;
  border-width: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 3em !important;
    color: black;
  }
}